import React, { FC } from 'react';
import { PageProps } from 'gatsby';

import Footer from '../old/components/Footer';
import PageHeader from '../old/components/style-components/PageHeader';
import SEO from '../new/components/SEO';

const Page404: FC<PageProps> = () => {
  return (
    <>
      <SEO title="404" />
      <div>
        <PageHeader>
          <h1>404</h1>
          <p>Sorry, we couldn&apos;t find that page!</p>
        </PageHeader>
      </div>
      <Footer />
    </>
  );
};

export default Page404;
